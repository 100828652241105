<template>
  <transition appear enter-active-class="animated fadeIn">
    <div>
      <div class="level-item">
        <h1 class="title is-1 page-title">UK TRAVEL INSURANCE</h1>
      </div>
      <div class="box b-shdw-3">
				<InfoButtonOptions :policyType="'ST'" :coverType="'ST'"/>

				<div class="content">
					<h2>Why do I need travel insurance for holidays in the UK?</h2>
					<p class="mb-4">
						When we think of travel insurance, one of our main concerns is the medical expenses cover, due to different healthcare situations abroad. However, for trips within the UK, medical expenses are not necessary, as these are covered by our NHS. However, for UK trips longer than 3 days, it is important to consider covering the cancellation cost of the holiday in the event of illness, injury or sadly redundancy. 
					</p>
					<h2>What type of travel insurance do I need?</h2>
					<p class="mb-4">
						We have Single Trip and Annual Multi-Trip policies available. Depending on how many UK trips you have planned, you may find that an Annual Multi-Trip policy may be more economical for you. Our policies will also cover any trips within the UK, providing you have pre-booked at least 2 nights away from home.
					</p>

					<h2>Medical expenses on a UK travel insurance policy are not a requirement, however, there are many other benefits of ensuring your staycation is protected:</h2>
					<ul class="mb-5">
						<li><strong>Transport / flight cancellations and delays</strong> – whether you have booked domestic flights, coach, train or a ferry crossing to your UK holiday destination, our travel insurance may cover the costs of any cancellations or delays during your trip. </li>
						<li><strong>Holiday cancellation</strong> - our holiday cancellation cover may be able to help you get your money back, if you must cancel your UK booking for reasons beyond your control. Please read our <a href="https://www.covered2go.co.uk/policy-wordings-mul">policy Terms and Conditions</a> carefully as the reasons for cancellation covered vary between our three tiers of cover level. </li>
						<li><strong>Holiday curtailment</strong> – in the even that your UK trip is cut short, due to illness, injury or another specified reason, you may recover the costs for the portion of the holiday you have missed out on.</li>
						<li><strong>Lost, stolen or damaged luggage</strong> - If your bags are lost, damaged or stolen, this would be covered up to the policy limits. Importantly, some of your more expensive gadgets such as, mobile phones and tablets can also be covered under our Platinum Policy. </li>
						<li><strong>Personal liability</strong> – Legal expenses can be provided if you’re held responsible for causing damages or injury to another person while on holiday.</li>
					</ul>

					<h2>Covid-19 cover is available on our UK travel insurance policies, the benefits of cover are outlined below:</h2>
					<ul class="mb-5">
						<li>Cancellation cover is provided if you need to cancel your holiday due to you or anyone named on your policy becoming ill with <strong>Covid-19*</strong>.</li>
						<li>Curtailment cover is provided if you need to cut short your holiday due to you or anyone named on your policy becoming ill with <strong>Covid-19*</strong>.</li>
						<li>Cover for denied boarding by your airline on your return journey, due to having or being suspected of having <strong>Covid-19*</strong>.</li>
					</ul>

					<h2>What other types of travel insurance should you consider for a staycation?</h2>
					<ul class="mb-5">
						<li><strong>Golf cover</strong> – if you're planning a golfing holiday, you may wish to add golf cover to ensure your gold equipment is covered.</li>
						<li><strong>Business cover</strong> – if you’re planning a business trip, you are covered to do non-manual work during your trip under this policy and it can be extended to cover manual work. Business Cover will also protect your business equipment if it is lost, stolen, damaged or delayed destroyed. If you are unable to fulfil your business trip, due to illness, we will cover some of the costs for a replacement employee to take over.</li>
						<li><strong>Sports and activities</strong> – if you’re into extreme sports, a standard policy may not automatically cover these, so ensure you add the extra protection you need for any specific sports and activities you are planning to take part in on your holiday. </li>
						<li><strong>Cruise cover</strong> - provides cover for Cruise trips as well as specific risks and activities associated with this type of holiday, such as, missed port departure and cabin confinement.</li>
						<li><strong>Winter sports cover</strong> - if you’ve booked to go skiing or snowboarding, ensure you have adequate cover in place to protect you for winter sports related claims.</li>
					</ul>

					<h2>Need UK travel insurance with pre-existing medical conditions cover?</h2>
					<p>
						If you have pre-existing medical conditions or a change in your health causes you to cancel your
						trip (including contracting Covid-19*), your Covered2go Gold or Platinum Policy will cover you,
						so long as your pre-existing medical conditions have been declared to and accepted by our bespoke
						online medical screening.
					</p>
					<p>
            You can find out more information and how to insure your medical conditions by following this link by <a href="https://www.covered2go.co.uk/pre-existing-medical-conditions">clicking
            here</a>.
            <!--						To ensure you have your pre-existing medical conditions fully covered, it is easy to purchase-->
<!--						the travel policy from <a href="https://www.covered2go.co.uk">covered2go.co.uk</a>, then declare-->
<!--						your medical conditions to our Medical Screening Service on +44 (0)330 311 2648.-->
					</p>
					<p>Once you’ve booked your UK holiday, don’t delay buying your travel insurance as unfortunately, reasons to cancel can happen at any time. </p>

					<a href="https://www.covered2go.co.uk/get-quote" class="text-c2g-orange">Get a quote for UK travel insurance today.</a>
					
				</div>
				
				<InfoButtonOptions :policyType="'ST'" :coverType="'ST'"/>
				<info-boxes/>
				<!-- <trustpilot-banner/> -->

				<div class="content mt-4">
					<p class="mb-1"><strong>*Please note:</strong></p>
					<p class="my-1">Available on Gold, Platinum and Cruise only.</p>
					<p class="my-1">Cover is provided so long as you did not cancel your trip because of FCDO, government or local authority advice relating to any infectious disease including Covid-19.</p>
					<p class="my-1">Terms and Conditions apply. Please see our <a >Policy Wording</a> for full details. </p>
				</div>

			</div>
    </div>
  </transition>
</template>

<script>
import InfoBoxes from "../components/common/InfoBoxes";
// import TrustpilotBanner from "@/components/common/trustpilot/TrustpilotBanner";
import InfoButtonOptions from "@/components/common/InfoButtonOptions";
		
export default {
  name: 'UKTravelInsurance',
  mixins: [],
  components: {
			InfoBoxes,
			//TrustpilotBanner,
			InfoButtonOptions
	},
};
</script>

<style lang="scss" scoped>
@import 'src/styles/views/info-page';
</style>
